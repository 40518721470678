<div *ngIf="invoices$ | async as invoices" class="px-8 xl:max-w-screen-xl xl:w-full xl:m-auto">
  <div class="grid grid-cols-5 md:grid-cols-12 items-center my-12">
    <h2 class="col-span-4 font-display font-normal text-5xl te-text-gradient-home inline-block">
      {{ 'pages.invoices.pageTitle' | translate }}
    </h2>
    <ng-container *ngIf="invoices?.data">
      <img
        ngSrc="./assets/img/filters.svg"
        class="inline-block ml-auto cursor-pointer md:hidden"
        width="20"
        height="21"
        (click)="toggleFilters = !toggleFilters"
        alt="Filter invoices"
      />
      <app-filters
        class="app-filters col-span-5 md:col-span-8 md:ml-auto"
        [filters]="invoiceFilters"
        [class.show]="toggleFilters"
        (emitActiveFilters)="updateFilters($event)"
      >
      </app-filters>
    </ng-container>
  </div>

  <div class="md:grid md:grid-cols-12 md:gap-16">
    <section
      *ngIf="invoices?.loading || invoices?.data; else invoicesError"
      [class.md:col-span-8]="accessDynamicTariff"
      [class.md:col-span-12]="!accessDynamicTariff"
    >
      <div *ngIf="invoices?.loading; else showInvoices">
        <div class="card rounded-4xl border border-grey bg-white !py-8">
          <app-ghost-table [numberOfRows]="10"></app-ghost-table>
        </div>
      </div>
      <ng-template #showInvoices>
        <div class="card rounded-4xl border border-grey bg-white !py-8">
          <div
            *ngIf="invoices?.data?.invoices?.length; else noResults"
            class="divide-y divide-grey">
            <app-invoice
              *ngFor="let invoice of invoices?.data?.invoices"
              [invoice]="invoice"
              [billingDetails]="invoices?.data?.billingDetails"
              class="inline-block w-full py-8 first:pt-0 last:pb-0"
            >
            </app-invoice>
          </div>
        </div>
        <ngb-pagination
          class="inline-block w-full mt-8"
          *ngIf="invoices?.data?.totalInvoices > invoicesPerPage"
          [(page)]="activePage"
          [pageSize]="invoicesPerPage"
          [collectionSize]="invoices?.data?.totalInvoices"
          [maxSize]="3"
          [rotate]="true"
          (pageChange)="getInvoices($event)"
        >
        </ngb-pagination>
      </ng-template>
    </section>

    <div *ngIf="accessDynamicTariff" class="aside md:col-span-4">
      <div class="card te-border-gradient-home">
        <span class="mb-2 text-grey-dark60 font-medium text-sm">
          {{ 'pages.invoices.sidebar.boxTitle' | translate }}
        </span>
        <h3>{{ 'pages.invoices.sidebar.title' | translate }}</h3>
        <div class="my-8 text-sm">
          {{ 'pages.invoices.sidebar.description' | translate }}
        </div>
        <a class="btn btn-secondary" routerLink="/consumptions">
          {{ 'pages.invoices.sidebar.readMore' | translate }}
        </a>
      </div>
    </div>
  </div>

  <app-faq
    class="w-full max-w-5xl block mx-auto"
    [title]="'components.faq.title' | translate"
    [faqURL]="'components.faq.links.invoices' | translate"
    [tag]="'my-totalenergies-invoices'"
  >
  </app-faq>
</div>

<ng-template #noResults>
  <div *ngIf="activeFilters?.year || activeFilters?.invoiceType; else noFilters">
    {{ 'pages.invoices.noResults.filters' | translate }}
  </div>
  <ng-template #noFilters>
    <div>
      {{ 'pages.invoices.noResults.noFilters' | translate }}
    </div>
  </ng-template>
</ng-template>

<ng-template #invoicesError>
  <app-alert
    [alert]="invoiceAlerts?.invoiceEndpointError"
    [class.md:col-span-8]="accessDynamicTariff"
    [class.md:col-span-12]="!accessDynamicTariff"
    class="inline-block w-full"
  ></app-alert>
</ng-template>
