import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { AppConfig } from '@app/shared/utils/app-config';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { KeycloakService } from 'keycloak-angular';
import { ScriptLoaderService } from './script-loader.service';
import { from, Observable, Subject, switchMap, takeUntil } from 'rxjs';
import { MainFacade } from '@app/core/facade/main.facade';
import { Site } from '../user/models/site.interface';
import { ContractDtoCuzoApi, ContractDtoTypeEnumCuzoApi } from '@app/shared/models/cuzo-be-contract';

interface Exnaton {
  api: {
    base: string;
    credentials: string;
    headers: {
      authorization: string;
    };
  };
  context: {
    accountRef: string;
  };
  theme: {
    textPrimary: string;
    primary: string;
    defaultShadow: number | string;
    defaultRadius: string;
    font: {};
    components: {};
  };
  locale: string;
  setConfig: (exnaton: Partial<Exnaton>) => Exnaton;
}

interface WindowExnaton extends Window {
  Exnaton?: Exnaton;
}

@Injectable()
export class ExnatonService {
  private window: WindowExnaton;
  private activeReference: string;
  private activeSiteId: string;
  private activeDeliveryPoint: string;
  private scriptLoaded$: Observable<boolean | undefined>;
  private notifier$ = new Subject<void>();

  get scriptLoaded(): Observable<boolean | undefined> {
    return this.scriptLoaded$;
  }

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private appConfig: AppConfig,
    private keycloakService: KeycloakService,
    private translateService: TranslateService,
    private facade: MainFacade,
    private scriptLoaderService: ScriptLoaderService
  ) {
    this.window = <Window>this.document.defaultView;
    this.scriptLoaded$ = this.loadAndSetConfig();

    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      if (event?.lang) {
        this.updateConfig();
      }
    });

    this.facade.activeSite$
      .pipe(
        takeUntil(this.notifier$),
        switchMap((site: Site) => {
          this.activeReference = this.facade.state$.value.reference;
          this.activeSiteId = site?.id;

          return this.facade.contracts$;
        })
      )
      .subscribe((contracts: ContractDtoCuzoApi[]) => {
        const contract = contracts.find(
          (contract: ContractDtoCuzoApi) => contract.type === ContractDtoTypeEnumCuzoApi.ELECTRICITY
        );
        this.activeDeliveryPoint = contract.deliveryPointReference;
        this.updateConfig();
      });
  }

  updateConfig(): void {
    const windowExnatonObject = this.window.Exnaton;
    const authToken = this.keycloakService.getKeycloakInstance().token;
    const baseUrl = `${this.appConfig.config.api.serverHost + this.appConfig.config.api.baseHref}/v1/dynamic-tariff`;

    if (windowExnatonObject && this.activeSiteId && this.activeDeliveryPoint) {
      windowExnatonObject.setConfig({
        locale: `${this.translateService.currentLang}-BE`,
        context: { accountRef: '__externalSubjectReference__' },
        api: {
          base: `${baseUrl}/${this.activeReference}/sites/${this.activeSiteId}/delivery-points/${this.activeDeliveryPoint}`,
          credentials: 'omit',
          headers: {
            authorization: `Bearer ${authToken}`,
          },
        },
        theme: {
          textPrimary: '#374649',
          primary: '#3F53FA',
          defaultShadow: 0,
          defaultRadius: '$lg',
          font: {
            heading: { family: 'Roboto', fontWeight: 500, fontSize: 16 },
          },
          components: {
            TitleHelperInfo: {
              iconProps: {
                backgroundColor: '#ffffff',
                color: '#B7CBD399',
                size: 18,
                iconSize: 28,
              },
            },
          },
        },
      });
    }
  }

  private loadAndSetConfig(): Observable<boolean | undefined> {
    return from(
      this.scriptLoaderService.loadScript('https://cdn.exnaton.net/js/molecules/latest/web-components.mjs').then(
        () => {
          if (this.window.Exnaton) {
            this.updateConfig();

            return true;
          }
        },
        () => {
          throw new Error();
        }
      )
    );
  }
}
