/* eslint-disable max-len */
import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage, PercentPipe } from '@angular/common';
import { GreenCertificateComponent } from './pages/green-certificate/green-certificate.component';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@app/shared/shared.module';
import { GcFormComponent } from './components/gc-form/gc-form.component';
import { ContactModule } from '../contact/contact.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChartModule } from '@app/modules/chart/chart.module';
import { BillingModule } from '../billing/billing.module';
import { EstimatedIndexComponent } from './pages/estimated-index/estimated-index.component';
import { NoEstimatedIndexComponent } from './components/no-estimated-index/no-estimated-index.component';
import { EstimatedIndexesComponent } from './components/estimated-indexes/estimated-indexes.component';
import { EstimatedIndexDetailComponent } from './components/estimated-index-detail/estimated-index-detail.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { AnalyticsModule } from '@app/shared/modules/analytics/analytics.module';
import { IndexModalComponent } from './components/modal/index-modal/index-modal.component';
import { IndexFormComponent } from './components/index-form/index-form.component';
import { EnergySelectionComponent } from './components/index-form/components/energy-selection/energy-selection.component';
import { EnergyComponent } from './components/index-form/components/energy/energy.component';
import { MeterSelectionComponent } from './components/index-form/components/energy/components/meter-selection/meter-selection.component';
import { MeterIndexComponent } from './components/index-form/components/energy/components/meter-index/meter-index.component';
import { LoaderModule } from '@app/shared/modules/loader/loader.module';
import { EnergyFilterPipe } from './pipes/energy-filter.pipe';
import { ConsumptionChartComponent } from './components/chart/consumption-chart/consumption-chart.component';
// prettier-ignore
import {
  UpdateMonthlyInstallmentComponent
} from './components/index-form/components/energy/components/update-monthly-installment/update-monthly-installment.component';
import { AllGoodComponent } from './components/index-form/components/energy/components/all-good/all-good.component';
import { NoConsumptionComponent } from './components/no-consumption/no-consumption.component';
import { EnergySwitcherComponent } from './components/energy-switcher/energy-switcher.component';
import { MeterReadingComponent } from './pages/meter-reading/meter-reading.component';
import { MeterTableComponent } from './components/meter-table/meter-table.component';
// eslint-disable-next-line max-len
import { UpdateSuccessComponent } from './components/index-form/components/energy/components/update-monthly-installment/components/update-success/update-success.component';
// eslint-disable-next-line max-len
import { MonthlyInstallmentFormComponent } from './components/index-form/components/energy/components/update-monthly-installment/components/monthly-installment-form/monthly-installment-form.component';
// eslint-disable-next-line max-len
import { WarningMessageComponent } from './components/index-form/components/energy/components/warning-message/warning-message.component';
import { IfIntroComponent } from './components/index-form/components/if-intro/if-intro.component';
import { ProvisionalIndexesComponent } from './components/meter-table/provisional-indexes/provisional-indexes.component';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { NgChartsModule } from 'ng2-charts';
import { MandateModalComponent } from './components/modal/mandate-modal/mandate-modal.component';
import { InjectionToggleComponent } from './components/injection-toggle/injection-toggle.component';
import { MeterSwitcherComponent } from './components/meter-switcher/meter-switcher.component';
import { GhostConsumptionChartComponent } from './components/chart/consumption-chart/ghost-consumption-chart/ghost-consumption-chart.component';
import { TotalConsumptionComponent } from './components/total-consumption/total-consumption.component';
import { PowerPeakGraphComponent } from './pages/power-peak-graph/power-peak-graph.component';
import { MaxConsumptionComponent } from './components/max-consumption/max-consumption.component';
import { AvarageConsumptionComponent } from './components/avarage-consumption/avarage-consumption.component';
import { PowerPeakQAndAComponent } from './components/power-peak-q-and-a/power-peak-q-and-a.component';
import { AlreadyOkMessageComponent } from './components/index-form/components/energy/components/update-monthly-installment/components/already-ok-message/already-ok-message.component';
import { CamelCasePipe } from '../../../shared/pipes/camel-case/camel-case.pipe';
import { HomeProfileComponent } from './pages/home-profile/home-profile.component';
import { HomeProfileFormComponent } from './components/home-profile-form/home-profile-form.component';
import { HomeProfilePanelComponent } from '@app/modules/customer-zone/consumption/components/home-profile-panel/home-profile-panel.component';
import { HomeProfileItemComponent } from './components/home-profile-item/home-profile-item.component';
import { HomeProfileProgressComponent } from './components/home-profile-progress/home-profile-progress.component';
import { EMoveComponent } from './components/e-move/e-move.component';
import { HomeProfileQuestionTypeComponent } from './components/home-profile-question-type/home-profile-question-type.component';
import { HomeProfileProgressWidgetComponent } from './components/home-profile-progress-widget/home-profile-progress-widget.component';
import { ConsumptionDashboardComponent } from './pages/dashboard/consumption-dashboard.component';
import { BasicTplComponent } from '@app/modules/customer-zone/consumption/pages/dashboard/templates/basic-tpl/basic-tpl.component';
import { SmartTplComponent } from './pages/dashboard/templates/smart-tpl/smart-tpl.component';
import { SolarTplComponent } from './pages/dashboard/templates/solar-tpl/solar-tpl.component';
import { SmartSolarTplComponent } from './pages/dashboard/templates/smart-solar-tpl/smart-solar-tpl.component';
import { PeakConsumptionWidgetComponent } from '@app/modules/customer-zone/consumption/pages/dashboard/widgets/peak-consumption-widget/peak-consumption-widget.component';
import { ErrorWidgetComponent } from './pages/dashboard/widgets/error-widget/error-widget.component';
import { LoaderWidgetComponent } from './pages/dashboard/widgets/loader-widget/loader-widget.component';
import { NoDataWidgetComponent } from './pages/dashboard/widgets/no-data-widget/no-data-widget.component';
import { MonthlyConsumptionChartWidgetComponent } from './pages/dashboard/widgets/monthly-consumption-chart-widget/monthly-consumption-chart-widget.component';
import { FluviusConsentsComponent } from './pages/fluvius-consents/fluvius-consents.component';
import { FluviusMandatesComponent } from './components/fluvius-mandates/fluvius-mandates.component';
import { UsageCategoriesPopupComponent } from './pages/dashboard/widgets/usage-categories-popup/usage-categories-popup.component';
import { DisaggregationChartWidgetComponent } from './pages/dashboard/widgets/disaggregation-chart-widget/disaggregation-chart-widget.component';
import { DisaggregationPodiumPlatformComponent } from './pages/dashboard/widgets/disaggregation-chart-widget/disaggregation-podium-platform/disaggregation-podium-platform.component';
import { ErrorAccessComponent } from '@app/modules/customer-zone/consumption/pages/error-access/error-access.component';
import { ObservableLoadingErrorPipe } from './pipes/observable-loading-error.pipe';
import { FirstLoginGuideComponent } from './pages/dashboard/wizards/first-login-guide/first-login-guide.component';
import { StepTemplateComponent } from './pages/dashboard/wizards/first-login-guide/step-template/step-template.component';
import { AdviceComponent } from './pages/advice/advice.component';
import { AdvicePanelComponent } from './components/advice-panel/advice-panel.component';
import { AdviceActionComponent } from './components/advice-action/advice-action.component';
import { AdviceItemComponent } from './components/advice-item/advice-item.component';
import { ForecastConsumptionWidgetComponent } from './pages/dashboard/widgets/forecast-consumption-widget/forecast-consumption-widget.component';
import { StepNavigationComponent } from './pages/dashboard/wizards/first-login-guide/step-navigation/step-navigation.component';
import { ForecastInjectionWidgetComponent } from './pages/dashboard/widgets/forecast-injection-widget/forecast-injection-widget.component';
import { AdviceInvestmentComponent } from './components/advice-investment/advice-investment.component';
import { MeterReadingChartWidgetComponent } from './pages/dashboard/widgets/meter-reading-chart-widget/meter-reading-chart-widget.component';
import { FluviusMandatesIntroComponent } from './components/fluvius-mandates-intro/fluvius-mandates-intro.component';
import { ComparisonConsumptionWidgetComponent } from './pages/dashboard/widgets/comparison-consumption-widget/comparison-consumption-widget.component';
import { EncodeIndexWidgetComponent } from './pages/dashboard/widgets/encode-index-widget/encode-index-widget.component';
import { CrossSellWidgetComponent } from './pages/dashboard/widgets/cross-sell-widget/cross-sell-widget.component';
import { CrossSellSmartWidgetComponent } from './pages/dashboard/widgets/cross-sell-smart-widget/cross-sell-smart-widget.component';
import { FaqModule } from '@app/shared/modules/faq/faq.module';
import { FluviusMandatesSectionComponent } from '@app/modules/customer-zone/consumption/components/fluvius-mandates-section/fluvius-mandates-section.component';
import { FluviusMandatesCtaComponent } from './components/fluvius-mandates-intro/fluvius-mandates-cta/fluvius-mandates-cta.component';
import { EliqDashboardComponent } from './pages/dashboard/parts/eliq-dashboard/eliq-dashboard.component';
import { DynamicTariffDashboardComponent } from './pages/dashboard/parts/dynamic-tariff-dashboard/dynamic-tariff-dashboard.component';
import { DynamicTariffComponent } from './pages/dynamic-tariff/dynamic-tariff.component';
import { SitesSwitcherComponent } from '@app/shared/components/sites-switcher/sites-switcher.component';
import { SuccessPopupComponent } from '@app/shared/components/success-popup/success-popup.component';

@NgModule({
  declarations: [
    GreenCertificateComponent,
    GcFormComponent,
    EstimatedIndexComponent,
    NoEstimatedIndexComponent,
    EstimatedIndexesComponent,
    EstimatedIndexDetailComponent,
    IndexModalComponent,
    IndexFormComponent,
    EnergySelectionComponent,
    MeterSelectionComponent,
    EnergyComponent,
    MeterIndexComponent,
    EnergyFilterPipe,
    ConsumptionChartComponent,
    NoConsumptionComponent,
    EnergySwitcherComponent,
    MeterReadingComponent,
    MeterTableComponent,
    UpdateMonthlyInstallmentComponent,
    AllGoodComponent,
    UpdateSuccessComponent,
    MonthlyInstallmentFormComponent,
    WarningMessageComponent,
    IfIntroComponent,
    ProvisionalIndexesComponent,
    MandateModalComponent,
    InjectionToggleComponent,
    MeterSwitcherComponent,
    GhostConsumptionChartComponent,
    TotalConsumptionComponent,
    PowerPeakGraphComponent,
    MaxConsumptionComponent,
    AvarageConsumptionComponent,
    PowerPeakQAndAComponent,
    AlreadyOkMessageComponent,
    CamelCasePipe,
    HomeProfileComponent,
    HomeProfileFormComponent,
    HomeProfilePanelComponent,
    HomeProfileItemComponent,
    HomeProfileProgressComponent,
    EMoveComponent,
    HomeProfileQuestionTypeComponent,
    HomeProfileProgressWidgetComponent,
    ConsumptionDashboardComponent,
    BasicTplComponent,
    SmartTplComponent,
    SolarTplComponent,
    SmartSolarTplComponent,
    PeakConsumptionWidgetComponent,
    ErrorWidgetComponent,
    LoaderWidgetComponent,
    NoDataWidgetComponent,
    MonthlyConsumptionChartWidgetComponent,
    FluviusConsentsComponent,
    FluviusMandatesComponent,
    UsageCategoriesPopupComponent,
    DisaggregationChartWidgetComponent,
    DisaggregationPodiumPlatformComponent,
    ErrorAccessComponent,
    ObservableLoadingErrorPipe,
    FirstLoginGuideComponent,
    StepTemplateComponent,
    AdviceComponent,
    AdvicePanelComponent,
    AdviceActionComponent,
    AdviceItemComponent,
    ForecastConsumptionWidgetComponent,
    StepNavigationComponent,
    ForecastInjectionWidgetComponent,
    AdviceInvestmentComponent,
    MeterReadingChartWidgetComponent,
    FluviusMandatesIntroComponent,
    ComparisonConsumptionWidgetComponent,
    EncodeIndexWidgetComponent,
    CrossSellWidgetComponent,
    CrossSellSmartWidgetComponent,
    FluviusMandatesSectionComponent,
    FluviusMandatesCtaComponent,
    EliqDashboardComponent,
    DynamicTariffDashboardComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    SharedModule,
    ContactModule,
    ReactiveFormsModule,
    BillingModule,
    NgbModule,
    RouterModule,
    AnalyticsModule,
    LoaderModule,
    NgChartsModule,
    NgOptimizedImage,
    ChartModule,
    FaqModule,
    SuccessPopupComponent,
    FormsModule,
    DynamicTariffComponent,
    SitesSwitcherComponent,
  ],
  providers: [PercentPipe],
})
export class ConsumptionModule {}

//Home profile

_('components.homeProfileForm.list.noEquipment.label');
_('components.homeProfileForm.list.house_type.itemTitle');
_('components.homeProfileForm.list.house_type.panelTitle');
_('components.homeProfileForm.list.house_type.label');
_('components.homeProfileForm.list.house_type.choices.apartment');
_('components.homeProfileForm.list.house_type.choices.house_mid_terraced');
_('components.homeProfileForm.list.house_type.choices.house_detached');
_('components.homeProfileForm.list.house_type.choices.semi_detached');
_('components.homeProfileForm.list.house_type.choices.other');
_('components.homeProfileForm.list.house_type.choices.house_end_terraced');
_('components.homeProfileForm.list.house_type.choices.house_bungalow');
_('components.homeProfileForm.list.house_type.choices.holiday_home');

_('components.homeProfileForm.list.living_area.itemTitle');
_('components.homeProfileForm.list.living_area.panelTitle');
_('components.homeProfileForm.list.living_area.label');
_('components.homeProfileForm.list.living_area.validation.required');
_('components.homeProfileForm.list.living_area.validation.min');
_('components.homeProfileForm.list.living_area.validation.max');

_('components.homeProfileForm.list.bedrooms.itemTitle');
_('components.homeProfileForm.list.bedrooms.panelTitle');
_('components.homeProfileForm.list.bedrooms.label');

_('components.homeProfileForm.list.persons.itemTitle');
_('components.homeProfileForm.list.persons.panelTitle');
_('components.homeProfileForm.list.persons.label');

_('components.homeProfileForm.list.heating_type_primary.itemTitle');
_('components.homeProfileForm.list.heating_type_primary.panelTitle');
_('components.homeProfileForm.list.heating_type_primary.label');
_('components.homeProfileForm.list.heating_type_primary.choices.gas');
_('components.homeProfileForm.list.heating_type_primary.choices.plug_in_electric_heaters');
_('components.homeProfileForm.list.heating_type_primary.choices.heat_pump');
_('components.homeProfileForm.list.heating_type_primary.choices.oil_boiler_with_radiators');
_('components.homeProfileForm.list.heating_type_primary.choices.other');
_('components.homeProfileForm.list.heating_type_primary.choices.gas_boiler_with_radiators');
_('components.homeProfileForm.list.heating_type_primary.choices.gas_room_heaters');
_('components.homeProfileForm.list.heating_type_primary.choices.night_storage_heaters');
_('components.homeProfileForm.list.heating_type_primary.choices.district_heating');
_('components.homeProfileForm.list.heating_type_primary.choices.floor_heat');
_('components.homeProfileForm.list.heating_type_primary.choices.electric_ir_panel');

_('components.homeProfileForm.list.hotwater_type.itemTitle');
_('components.homeProfileForm.list.hotwater_type.panelTitle');
_('components.homeProfileForm.list.hotwater_type.label');
_('components.homeProfileForm.list.hotwater_type.choices.gas_boiler_with_radiators');
_('components.homeProfileForm.list.hotwater_type.choices.hot_water_tank_with_an_electric_immersion_heater');
_('components.homeProfileForm.list.hotwater_type.choices.hot_water_heat_pump');
_('components.homeProfileForm.list.hotwater_type.choices.solar_hot_water_with_electric_backup');
_('components.homeProfileForm.list.hotwater_type.choices.oil_boiler');
_('components.homeProfileForm.list.hotwater_type.choices.other');
_('components.homeProfileForm.list.hotwater_type.choices.air_water_pump');
_('components.homeProfileForm.list.hotwater_type.choices.gas_hot_water_tank');
_('components.homeProfileForm.list.hotwater_type.choices.district_heating');
_('components.homeProfileForm.list.hotwater_type.choices.electric_instant_hot_water');
_('components.homeProfileForm.list.hotwater_type.choices.gas_geyser');

_('components.homeProfileForm.list.appliances.itemTitle');
_('components.homeProfileForm.list.appliances.panelTitle');
_('components.homeProfileForm.list.appliances.label');
_('components.homeProfileForm.list.appliances.dishwashers.label');
_('components.homeProfileForm.list.appliances.microwaves.label');
_('components.homeProfileForm.list.appliances.washing_machines.label');
_('components.homeProfileForm.list.appliances.tumble_dryer.label');
_('components.homeProfileForm.list.appliances.tv.label');
_('components.homeProfileForm.list.appliances.game_consoles.label');
_('components.homeProfileForm.list.appliances.stationary_computers.label');
_('components.homeProfileForm.list.appliances.laptops.label');
_('components.homeProfileForm.list.appliances.electric_tumble_dryer.label');
_('components.homeProfileForm.list.appliances.gas_tumble_dryer.label');
_('components.homeProfileForm.list.appliances.heatpump_tumble_dryer.label');

_('components.homeProfileForm.list.cooking.itemTitle');
_('components.homeProfileForm.list.cooking.panelTitle');
_('components.homeProfileForm.list.cooking.label');
_('components.homeProfileForm.list.cooking.gas_stoves.label');
_('components.homeProfileForm.list.cooking.gas_ovens.label');
_('components.homeProfileForm.list.cooking.electric_stoves.label');
_('components.homeProfileForm.list.cooking.electric_ovens.label');

_('components.homeProfileForm.list.extras.itemTitle');
_('components.homeProfileForm.list.extras.panelTitle');
_('components.homeProfileForm.list.extras.label');
_('components.homeProfileForm.list.extras.electric_cars.label');
_('components.homeProfileForm.list.extras.swimming_pool.label');
_('components.homeProfileForm.list.extras.hot_tub.label');
_('components.homeProfileForm.list.extras.electric_heated_sauna_times_per_month.label');
_('components.homeProfileForm.list.extras.air_conditioners.label');
_('components.homeProfileForm.list.extras.solar_installed.label');
_('components.homeProfileForm.list.extras.electric_swimming_pool.label');
_('components.homeProfileForm.list.extras.gas_swimming_pool.label');

_('components.homeProfileForm.list.fridgesAndFreezers.itemTitle');
_('components.homeProfileForm.list.fridgesAndFreezers.panelTitle');
_('components.homeProfileForm.list.fridgesAndFreezers.label');
_('components.homeProfileForm.list.fridgesAndFreezers.fridges.label');
_('components.homeProfileForm.list.fridgesAndFreezers.freezers.label');
_('components.homeProfileForm.list.fridgesAndFreezers.fridge_freezers.label');

_('components.homeProfileForm.list.property_ownership.itemTitle');
_('components.homeProfileForm.list.property_ownership.panelTitle');
_('components.homeProfileForm.list.property_ownership.label');
_('components.homeProfileForm.list.property_ownership.owner.label');
_('components.homeProfileForm.list.property_ownership.tenant.label');

_('components.homeProfileForm.list.elec_yearly_estimate.itemTitle');
_('components.homeProfileForm.list.elec_yearly_estimate.panelTitle');
_('components.homeProfileForm.list.elec_yearly_estimate.description');
_('components.homeProfileForm.list.elec_yearly_estimate.elec_yearly_estimate.label');
_('components.homeProfileForm.list.elec_yearly_estimate.elec_yearly_estimate.validation.required');
_('components.homeProfileForm.list.elec_yearly_estimate.elec_yearly_estimate.validation.min');
_('components.homeProfileForm.list.elec_yearly_estimate.elec_yearly_estimate.validation.max');

_('components.homeProfileForm.list.heating_type_other.label');

_('components.homeProfileForm.update.success.label');
_('components.homeProfileForm.update.error.label');
_('components.homeProfileForm.general.kwhByYear');
_('components.homeProfileForm.general.m2');

// Advice
_('components.advice.general.tipDone');
_('components.advice.general.tipNotApplicable');
_('components.advice.general.resetTip');
_('components.advice.general.markAsTodo');
_('components.advice.status.none');
_('components.advice.status.implemented');
_('components.advice.status.skipped');
_('components.advice.status.all');
_('components.advice.actionCallback.none');
_('components.advice.actionCallback.implemented');
_('components.advice.actionCallback.skipped');

_('components.advice.list.install_a_smart_control_for_your_heating_system.title');
_('components.advice.list.install_a_smart_control_for_your_heating_system.content');
_('components.advice.list.install_a_smart_control_for_your_heating_system.amountBeingSaved');
_('components.advice.list.install_a_smart_control_for_your_heating_system.investmentNeeded');

_('components.advice.list.be_economical_with_your_electric_underfloor_heating.title');
_('components.advice.list.be_economical_with_your_electric_underfloor_heating.content');
_('components.advice.list.be_economical_with_your_electric_underfloor_heating.amountBeingSaved');
_('components.advice.list.be_economical_with_your_electric_underfloor_heating.investmentNeeded');

_('components.advice.list.adjust_the_indoor_temperature.title');
_('components.advice.list.adjust_the_indoor_temperature.content');
_('components.advice.list.adjust_the_indoor_temperature.amountBeingSaved');
_('components.advice.list.adjust_the_indoor_temperature.investmentNeeded');

_('components.advice.list.install_air_source_heat_pump.title');
_('components.advice.list.install_air_source_heat_pump.content');
_('components.advice.list.install_air_source_heat_pump.amountBeingSaved');
_('components.advice.list.install_air_source_heat_pump.investmentNeeded');

_('components.advice.list.switch_to_new_energy-efficient_windows.title');
_('components.advice.list.switch_to_new_energy-efficient_windows.content');
_('components.advice.list.switch_to_new_energy-efficient_windows.amountBeingSaved');
_('components.advice.list.switch_to_new_energy-efficient_windows.investmentNeeded');

_('components.advice.list.install_waterborne_heat_pump.title');
_('components.advice.list.install_waterborne_heat_pump.content');
_('components.advice.list.install_waterborne_heat_pump.amountBeingSaved');
_('components.advice.list.install_waterborne_heat_pump.investmentNeeded');

_('components.advice.list.heat_recovery_with_ftx.title');
_('components.advice.list.heat_recovery_with_ftx.content');
_('components.advice.list.heat_recovery_with_ftx.amountBeingSaved');
_('components.advice.list.heat_recovery_with_ftx.investmentNeeded');

_('components.advice.list.insulate_attic_and_ceilings.title');
_('components.advice.list.insulate_attic_and_ceilings.content');
_('components.advice.list.insulate_attic_and_ceilings.amountBeingSaved');
_('components.advice.list.insulate_attic_and_ceilings.investmentNeeded');

_('components.advice.list.apartment_shower.title');
_('components.advice.list.apartment_shower.content');
_('components.advice.list.apartment_shower.amountBeingSaved');
_('components.advice.list.apartment_shower.investmentNeeded');

_('components.advice.list.take_care_of_your_cranes.title');
_('components.advice.list.take_care_of_your_cranes.content');
_('components.advice.list.take_care_of_your_cranes.amountBeingSaved');
_('components.advice.list.take_care_of_your_cranes.investmentNeeded');

_('components.advice.list.take_a_short_shower_every_now_and_then.title');
_('components.advice.list.take_a_short_shower_every_now_and_then.content');
_('components.advice.list.take_a_short_shower_every_now_and_then.amountBeingSaved');
_('components.advice.list.take_a_short_shower_every_now_and_then.investmentNeeded');

_('components.advice.list.an_old_water_heater_should_be_replaced.title');
_('components.advice.list.an_old_water_heater_should_be_replaced.content');
_('components.advice.list.an_old_water_heater_should_be_replaced.amountBeingSaved');
_('components.advice.list.an_old_water_heater_should_be_replaced.investmentNeeded');

_('components.advice.list.replace_old_lamps_with_led_lighting.title');
_('components.advice.list.replace_old_lamps_with_led_lighting.content');
_('components.advice.list.replace_old_lamps_with_led_lighting.amountBeingSaved');
_('components.advice.list.replace_old_lamps_with_led_lighting.investmentNeeded');

_('components.advice.list.invest_in_a_new_fridge_&_freezer.title');
_('components.advice.list.invest_in_a_new_fridge_&_freezer.content');
_('components.advice.list.invest_in_a_new_fridge_&_freezer.amountBeingSaved');
_('components.advice.list.invest_in_a_new_fridge_&_freezer.investmentNeeded');

_('components.advice.list.install_solar_cells.title');
_('components.advice.list.install_solar_cells.content');
_('components.advice.list.install_solar_cells.amountBeingSaved');
_('components.advice.list.install_solar_cells.investmentNeeded');

_('components.advice.list.lower_your_standby_consumption.title');
_('components.advice.list.lower_your_standby_consumption.content');
_('components.advice.list.lower_your_standby_consumption.amountBeingSaved');
_('components.advice.list.lower_your_standby_consumption.investmentNeeded');

_('components.advice.list.think_about_how_you_dry_your_laundry.title');
_('components.advice.list.think_about_how_you_dry_your_laundry.content');
_('components.advice.list.think_about_how_you_dry_your_laundry.amountBeingSaved');
_('components.advice.list.think_about_how_you_dry_your_laundry.investmentNeeded');

_('components.advice.list.control_your_air_conditioning.title');
_('components.advice.list.control_your_air_conditioning.content');
_('components.advice.list.control_your_air_conditioning.amountBeingSaved');
_('components.advice.list.control_your_air_conditioning.investmentNeeded');

_('components.advice.list.clean_filter_air_air_pump.title');
_('components.advice.list.clean_filter_air_air_pump.content');
_('components.advice.list.clean_filter_air_air_pump.amountBeingSaved');
_('components.advice.list.clean_filter_air_air_pump.investmentNeeded');

_('components.advice.list.insulate_windows_doors.title');
_('components.advice.list.insulate_windows_doors.content');
_('components.advice.list.insulate_windows_doors.amountBeingSaved');
_('components.advice.list.insulate_windows_doors.investmentNeeded');

_('components.advice.list.optimize_outdoor_lighting.title');
_('components.advice.list.optimize_outdoor_lighting.content');
_('components.advice.list.optimize_outdoor_lighting.amountBeingSaved');
_('components.advice.list.optimize_outdoor_lighting.investmentNeeded');

_('components.advice.list.wash_when_washing_machine_is_full.title');
_('components.advice.list.wash_when_washing_machine_is_full.content');
_('components.advice.list.wash_when_washing_machine_is_full.amountBeingSaved');
_('components.advice.list.wash_when_washing_machine_is_full.investmentNeeded');

_('components.advice.list.wash_when_dishwasher_is_full.title');
_('components.advice.list.wash_when_dishwasher_is_full.content');
_('components.advice.list.wash_when_dishwasher_is_full.amountBeingSaved');
_('components.advice.list.wash_when_dishwasher_is_full.investmentNeeded');

_('components.advice.list.control_temperature_fridge_freezer.title');
_('components.advice.list.control_temperature_fridge_freezer.content');
_('components.advice.list.control_temperature_fridge_freezer.amountBeingSaved');
_('components.advice.list.control_temperature_fridge_freezer.investmentNeeded');

_('components.advice.list.keep_it_clean_behind_fridge.title');
_('components.advice.list.keep_it_clean_behind_fridge.content');
_('components.advice.list.keep_it_clean_behind_fridge.amountBeingSaved');
_('components.advice.list.keep_it_clean_behind_fridge.investmentNeeded');

_('components.advice.list.be_careful_with_standby_consumption_for_computers.title');
_('components.advice.list.be_careful_with_standby_consumption_for_computers.content');
_('components.advice.list.be_careful_with_standby_consumption_for_computers.amountBeingSaved');
_('components.advice.list.be_careful_with_standby_consumption_for_computers.investmentNeeded');

_('components.advice.list.use_water_kettle_instead_of_stove.title');
_('components.advice.list.use_water_kettle_instead_of_stove.content');
_('components.advice.list.use_water_kettle_instead_of_stove.amountBeingSaved');
_('components.advice.list.use_water_kettle_instead_of_stove.investmentNeeded');

_('components.advice.list.extra_underfloor_insulation.title');
_('components.advice.list.extra_underfloor_insulation.content');
_('components.advice.list.extra_underfloor_insulation.amountBeingSaved');
_('components.advice.list.extra_underfloor_insulation.investmentNeeded');

_('components.advice.list.extra_insulation_for_water_heater.title');
_('components.advice.list.extra_insulation_for_water_heater.content');
_('components.advice.list.extra_insulation_for_water_heater.amountBeingSaved');
_('components.advice.list.extra_insulation_for_water_heater.investmentNeeded');

_('components.advice.list.extra_insulation_for_water_pipes.title');
_('components.advice.list.extra_insulation_for_water_pipes.content');
_('components.advice.list.extra_insulation_for_water_pipes.amountBeingSaved');
_('components.advice.list.extra_insulation_for_water_pipes.investmentNeeded');

_('components.advice.list.defrost_fridge_freezer.title');
_('components.advice.list.defrost_fridge_freezer.content');
_('components.advice.list.defrost_fridge_freezer.amountBeingSaved');
_('components.advice.list.defrost_fridge_freezer.investmentNeeded');

_('components.advice.list.temperature_washing_machine.title');
_('components.advice.list.temperature_washing_machine.content');
_('components.advice.list.temperature_washing_machine.amountBeingSaved');
_('components.advice.list.temperature_washing_machine.investmentNeeded');

_('components.advice.list.carpets_do_insulate.title');
_('components.advice.list.carpets_do_insulate.content');
_('components.advice.list.carpets_do_insulate.amountBeingSaved');
_('components.advice.list.carpets_do_insulate.investmentNeeded');

_('components.advice.list.be_mindful_with_ironing.title');
_('components.advice.list.be_mindful_with_ironing.content');
_('components.advice.list.be_mindful_with_ironing.amountBeingSaved');
_('components.advice.list.be_mindful_with_ironing.investmentNeeded');

_('components.advice.list.old_freezer.title');
_('components.advice.list.old_freezer.content');
_('components.advice.list.old_freezer.amountBeingSaved');
_('components.advice.list.old_freezer.investmentNeeded');

_('components.advice.list.heat_leakage_through_chimney.title');
_('components.advice.list.heat_leakage_through_chimney.content');
_('components.advice.list.heat_leakage_through_chimney.amountBeingSaved');
_('components.advice.list.heat_leakage_through_chimney.investmentNeeded');

// Forecast widgets
_('components.widgets.consumptionForecast.electricity.title');
_('components.widgets.consumptionForecast.electricity.tooltip.warning');
_('components.widgets.consumptionForecast.electricity.tooltip.label');
_('components.widgets.consumptionForecast.electricity.body.forecast');
_('components.widgets.consumptionForecast.gas.title');
_('components.widgets.consumptionForecast.gas.tooltip.warning');
_('components.widgets.consumptionForecast.gas.tooltip.label');
_('components.widgets.consumptionForecast.gas.body.forecast');

// Comparison widgets
_('components.widgets.consumptionComparison.consumption');
_('components.widgets.consumptionComparison.electricity.title');
_('components.widgets.consumptionComparison.electricity.tooltip.label');
_('components.widgets.consumptionComparison.gas.title');
_('components.widgets.consumptionComparison.gas.tooltip.label');

_('components.fluviusConsents.intro.missingRequiredMandate.banner.content');
_('components.fluviusConsents.intro.mandates.banner.content');

_('components.fluviusConsents.intro.requestedMandates.title');
_('components.fluviusConsents.intro.requestedMandates.content');
_('components.fluviusConsents.intro.requestedMandates.cta.label');
_('components.fluviusConsents.intro.requestedMandates.cta.link');

_('components.fluviusConsents.intro.mandates.title');
_('components.fluviusConsents.intro.mandates.content');
_('components.fluviusConsents.intro.mandates.cta.label');
_('components.fluviusConsents.intro.mandates.cta.link');
_('components.fluviusConsents.intro.mandates.cta.energy.electricity');
_('components.fluviusConsents.intro.mandates.cta.energy.gas');

_('components.fluviusMandates.mandatesTitle.electricity');
_('components.fluviusMandates.mandatesTitle.gas');
_('components.fluviusMandates.meter.electricity');
_('components.fluviusMandates.meter.gas');
_('components.fluviusMandates.granularity.period.quarter_hour');
_('components.fluviusMandates.granularity.period.day');
_('components.fluviusMandates.granularity.period.hour');

_('components.fluviusConsents.intro.faq.noMandate.item_one.title');
_('components.fluviusConsents.intro.faq.noMandate.item_one.content');
_('components.fluviusConsents.intro.faq.noMandate.item_two.title');
_('components.fluviusConsents.intro.faq.noMandate.item_two.content');
_('components.fluviusConsents.intro.faq.noMandate.item_three.title');
_('components.fluviusConsents.intro.faq.noMandate.item_three.content');

_('components.fluviusConsents.intro.faq.missingRequiredMandate.item_one.title');
_('components.fluviusConsents.intro.faq.missingRequiredMandate.item_one.content');
_('components.fluviusConsents.intro.faq.missingRequiredMandate.item_two.title');
_('components.fluviusConsents.intro.faq.missingRequiredMandate.item_two.content');
_('components.fluviusConsents.intro.faq.missingRequiredMandate.item_three.title');
_('components.fluviusConsents.intro.faq.missingRequiredMandate.item_three.content');

_('components.fluviusMandates.errorStatus.expired');
_('components.fluviusMandates.errorStatus.expiringSoon');
_('components.fluviusMandates.errorStatus.granularity');
_('components.fluviusMandates.errorStatus.mandateRequested');
_('components.fluviusMandates.errorStatus.noMandate');

_('pages.meterReading.pageTitle');
_('pages.estimatedIndex.pageTitle');
_('pages.greenCertificates.pageTitle');
_('pages.meterReading.NoContract');
_('pages.yearlyConsumption.pageTitle');
_('pages.consumption.pageTitle');
_('pages.advice.pageTitle');
_('pages.fluviusConsents.pageTitle');
_('components.greenCertificate.form.typeOptions.cwape');
_('components.greenCertificate.form.typeOptions.brugel');
_('pages.yearlyConsumption.fluviusError.title');
_('pages.yearlyConsumption.fluviusError.description');

// First login guide
_('components.firstLoginGuide.step1.title');
_('components.firstLoginGuide.step1.description');
_('components.firstLoginGuide.step2.title');
_('components.firstLoginGuide.step2.description');
_('components.firstLoginGuide.step3.title');
_('components.firstLoginGuide.step3.description');
_('components.firstLoginGuide.step4.title');
_('components.firstLoginGuide.step4.description');

// Usage Category Popup
_('components.usageCategoryPopup.categories.heating');
_('components.usageCategoryPopup.categories.washing');
_('components.usageCategoryPopup.categories.water_heating');
_('components.usageCategoryPopup.categories.lighting');
_('components.usageCategoryPopup.categories.consumer_electronics');
_('components.usageCategoryPopup.categories.cooking');
_('components.usageCategoryPopup.categories.always_on');
_('components.usageCategoryPopup.categories.fridge_freezer');
_('components.usageCategoryPopup.categories.cooling');
_('components.usageCategoryPopup.categories.electric_vehicle');
_('components.usageCategoryPopup.categories.other');

// TOTAL HOUR
// production
_('components.estimatedIndexDetail.form.meterInput.TOTAL_HOUR.PRODUCTION.label');
_('components.estimatedIndexDetail.form.meterInput.TOTAL_HOUR.PRODUCTION.tooltip');
// injection
_('components.estimatedIndexDetail.form.meterInput.TOTAL_HOUR.INJECTION.label');
_('components.estimatedIndexDetail.form.meterInput.TOTAL_HOUR.INJECTION.tooltip');
_('components.estimatedIndexDetail.form.meterInput.TOTAL_HOUR.INJECTION_READING.label');
_('components.estimatedIndexDetail.form.meterInput.TOTAL_HOUR.INJECTION_READING.tooltip');
// consumption
_('components.estimatedIndexDetail.form.meterInput.TOTAL_HOUR.CONSUMPTION.label');
_('components.estimatedIndexDetail.form.meterInput.TOTAL_HOUR.CONSUMPTION.tooltip');
_('components.estimatedIndexDetail.form.meterInput.TOTAL_HOUR.READING.label');
_('components.estimatedIndexDetail.form.meterInput.TOTAL_HOUR.READING.tooltip');

// HIGH
// production
_('components.estimatedIndexDetail.form.meterInput.HIGH.PRODUCTION.label');
_('components.estimatedIndexDetail.form.meterInput.HIGH.PRODUCTION.tooltip');
// injection
_('components.estimatedIndexDetail.form.meterInput.HIGH.INJECTION.label');
_('components.estimatedIndexDetail.form.meterInput.HIGH.INJECTION.tooltip');
_('components.estimatedIndexDetail.form.meterInput.HIGH.INJECTION_READING.label');
_('components.estimatedIndexDetail.form.meterInput.HIGH.INJECTION_READING.tooltip');
// consumption
_('components.estimatedIndexDetail.form.meterInput.HIGH.CONSUMPTION.label');
_('components.estimatedIndexDetail.form.meterInput.HIGH.CONSUMPTION.tooltip');
_('components.estimatedIndexDetail.form.meterInput.HIGH.READING.label');
_('components.estimatedIndexDetail.form.meterInput.HIGH.READING.tooltip');

// LOW
// production
_('components.estimatedIndexDetail.form.meterInput.LOW.PRODUCTION.label');
_('components.estimatedIndexDetail.form.meterInput.LOW.PRODUCTION.tooltip');
// injection
_('components.estimatedIndexDetail.form.meterInput.LOW.INJECTION.label');
_('components.estimatedIndexDetail.form.meterInput.LOW.INJECTION.tooltip');
_('components.estimatedIndexDetail.form.meterInput.LOW.INJECTION_READING.label');
_('components.estimatedIndexDetail.form.meterInput.LOW.INJECTION_READING.tooltip');
// consumption
_('components.estimatedIndexDetail.form.meterInput.LOW.CONSUMPTION.label');
_('components.estimatedIndexDetail.form.meterInput.LOW.CONSUMPTION.tooltip');
_('components.estimatedIndexDetail.form.meterInput.LOW.READING.label');
_('components.estimatedIndexDetail.form.meterInput.LOW.READING.tooltip');

// MONO
// production
_('components.estimatedIndexDetail.form.meterInput.MONO.PRODUCTION.label');
_('components.estimatedIndexDetail.form.meterInput.MONO.PRODUCTION.tooltip');
// injection
_('components.estimatedIndexDetail.form.meterInput.MONO.INJECTION.label');
_('components.estimatedIndexDetail.form.meterInput.MONO.INJECTION.tooltip');
_('components.estimatedIndexDetail.form.meterInput.MONO.INJECTION_READING.label');
_('components.estimatedIndexDetail.form.meterInput.MONO.INJECTION_READING.tooltip');
// consumption
_('components.estimatedIndexDetail.form.meterInput.MONO.CONSUMPTION.label');
_('components.estimatedIndexDetail.form.meterInput.MONO.CONSUMPTION.tooltip');
_('components.estimatedIndexDetail.form.meterInput.MONO.READING.label');
_('components.estimatedIndexDetail.form.meterInput.MONO.READING.tooltip');

// NIGHT_EXCLUSIVE
// production
_('components.estimatedIndexDetail.form.meterInput.NIGHT_EXCLUSIVE.PRODUCTION.label');
_('components.estimatedIndexDetail.form.meterInput.NIGHT_EXCLUSIVE.PRODUCTION.tooltip');
// injection
_('components.estimatedIndexDetail.form.meterInput.NIGHT_EXCLUSIVE.INJECTION.label');
_('components.estimatedIndexDetail.form.meterInput.NIGHT_EXCLUSIVE.INJECTION.tooltip');
_('components.estimatedIndexDetail.form.meterInput.NIGHT_EXCLUSIVE.INJECTION_READING.label');
_('components.estimatedIndexDetail.form.meterInput.NIGHT_EXCLUSIVE.INJECTION_READING.tooltip');
// consumption
_('components.estimatedIndexDetail.form.meterInput.NIGHT_EXCLUSIVE.CONSUMPTION.label');
_('components.estimatedIndexDetail.form.meterInput.NIGHT_EXCLUSIVE.CONSUMPTION.tooltip');
_('components.estimatedIndexDetail.form.meterInput.NIGHT_EXCLUSIVE.READING.label');
_('components.estimatedIndexDetail.form.meterInput.NIGHT_EXCLUSIVE.READING.tooltip');

_('components.consumption.index.monthlyInstallment.warning.serviceNotAvailable.title');
_('components.consumption.index.monthlyInstallment.warning.serviceNotAvailable.content');
_('components.consumption.index.indexDate');
_('components.consumption.meterType.high.label');
_('components.consumption.meterType.high.tooltip');
_('components.consumption.meterType.low.label');
_('components.consumption.meterType.low.tooltip');
_('components.consumption.meterType.mono.label');
_('components.consumption.meterType.mono.tooltip');
_('components.consumption.meterType.night_exclusive.label');
_('components.consumption.meterType.night_exclusive.tooltip');
_('components.consumption.meterType.total_hour.label');
_('components.consumption.meterType.total_hour.tooltip');
_('components.consumption.success.viewIntermediateIndexes');
_('components.consumption.success.indexPosted.title');
_('components.consumption.success.indexPosted.content');
_('components.consumption.success.allGood.title');
_('components.consumption.success.allGood.content');
_('components.indexModal.MeterNumber');
_('components.indexModal.electricityTitle');
_('components.indexModal.energyType');
_('components.indexModal.gasTitle');
_('components.noConsumption.explanation.electricity');
_('components.noConsumption.explanation.gas');
_('components.consumption.mandateWindow.fluviusFlow.startSlide.description.0');
_('components.consumption.mandateWindow.fluviusFlow.selectEnergySlide.description.0');
_('components.consumption.mandateWindow.fluviusFlow.approveFromSlide.description.0');
_('components.consumption.mandateWindow.fluviusFlow.confirmRequestSlide.description.0');
_('components.consumption.mandateWindow.fluviusFlow.submitAnotherSlide.description.0');
_('components.consumption.mandateWindow.fluviusFlow.startSlide.description.1');
_('components.consumption.mandateWindow.fluviusFlow.selectEnergySlide.description.1');
_('components.consumption.mandateWindow.fluviusFlow.approveFromSlide.description.1');
_('components.consumption.mandateWindow.fluviusFlow.confirmRequestSlide.description.1');
_('components.consumption.mandateWindow.fluviusFlow.submitAnotherSlide.description.1');
_('components.consumption.granularitySwitcher.quarter-hour');
_('components.consumption.granularitySwitcher.hour');
_('components.consumption.granularitySwitcher.day');
_('components.consumption.granularitySwitcher.week');
_('components.consumption.granularitySwitcher.month');
_('components.consumption.granularitySwitcher.year');
_('components.consumption.totalConsumptionBox.high_consumption');
_('components.consumption.totalConsumptionBox.low_consumption');
_('components.consumption.totalConsumptionBox.total_hour_consumption');
_('components.consumption.totalConsumptionBox.total_hour_injection');
_('components.consumption.totalConsumptionBox.high_injection');
_('components.consumption.totalConsumptionBox.low_injection');
_('components.consumption.chart.high.title');
_('components.consumption.chart.low.title');
_('components.consumption.chart.total_hour.title');
_('components.consumption.chart.production_high.title');
_('components.consumption.chart.production_low.title');
_('components.consumption.chart.production_total_hour.title');
_('components.widgets.monthlyConsumption.tooltip.no-smart-no-solar');
_('components.widgets.monthlyConsumption.tooltip.smart-no-solar');
_('components.widgets.monthlyConsumption.tooltip.smart-solar');
_('components.widgets.monthlyConsumption.tooltip.gas');
_('components.widgets.monthlyConsumption.tooltipWarning');
_('components.widgets.monthlyConsumption.chart.tooltip.injection');
_('components.widgets.monthlyConsumption.chart.legend.injection');
_('components.widgets.monthlyConsumption.chart.tooltip.consumption');
_('components.widgets.monthlyConsumption.chart.legend.consumption');
_('components.widgets.monthlyConsumption.chart.tooltip.estimation');
_('components.widgets.monthlyConsumption.chart.legend.estimation');
_('components.widgets.disaggreagtionChart.title.electricity');
_('components.widgets.disaggreagtionChart.title.gas');
_('components.widgets.disaggreagtionChart.tooltip.no-smart-no-solar');
_('components.widgets.disaggreagtionChart.tooltip.smart-no-solar');
_('components.widgets.disaggreagtionChart.tooltip.smart-solar');
_('components.widgets.disaggreagtionChart.tooltipWarning');
_('components.widgets.crossSell.title.boilerMaintenance');
_('components.widgets.crossSell.title.pvInstallation');
_('components.widgets.crossSell.title.evChargingPoint');
_('components.widgets.crossSell.title.battery');
_('components.widgets.crossSell.title.disclaimerSmartMeter');
_('components.widgets.crossSell.description.boilerMaintenance');
_('components.widgets.crossSell.description.pvInstallation');
_('components.widgets.crossSell.description.evChargingPoint');
_('components.widgets.crossSell.description.battery');
_('components.widgets.crossSell.description.disclaimerSmartMeter');
_('components.widgets.crossSell.cta.boilerMaintenance');
_('components.widgets.crossSell.cta.pvInstallation');
_('components.widgets.crossSell.cta.evChargingPoint');
_('components.widgets.crossSell.cta.battery');
_('components.widgets.crossSell.cta.disclaimerSmartMeter');
_('components.widgets.errors.somethingWentWrong.banner');
_('components.widgets.errors.cannotDisplayConsumption.title');
_('components.widgets.errors.cannotDisplayConsumption.banner');
_('components.widgets.meterReadingChart.tooltip.no-smart-no-solar');
_('errorMessages.serverError');
_('errorMessages.serverErrorFluvius');
_('general.noData');
_('general.noDataFluvius');
